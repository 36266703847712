.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table td, .table th {
  font-size: 13px !important;

}


/* Style for the modal */
#onloadModal {
    display: none; /* Hidden by default */
    position: fixed;
    
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
    align-items:'center';
    justify-content: 'center';
    padding:'auto';
}

/* Modal content */
.modal-content {
    background-color: #fefefe;
    margin:  auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 40%; /* Could be more or less, depending on screen size */
    align-items:'center';
    justify-content: 'center';
    top:45vh;
}

/* Close button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
.bg-menu-theme .menu-inner > .menu-item.active > .menu-link,
.bg-menu-theme .menu-inner > .menu-item.active > .menu-link:focus,
.bg-menu-theme .menu-inner > .menu-item.selected > .menu-link,
.bg-menu-theme .menu-inner > .menu-item.selected > .menu-link:focus {
    color: #696cff;
    background-color: rgba(105, 108, 255, 0.16) !important;
    /* Add additional styles for focus state if needed */
}

.menu-item button{
  border: none;
  width: 80%;
}


.hide{
  display : none;
}



/* Custom Paginator Styles */
.custom-paginator {
  background-color: #f8f9fa; /* Light background */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Padding around the paginator */
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Space out elements */
  align-items: center; /* Center items vertically */
}

.custom-paginator .p-paginator-page {
  border: 1px solid #007bff; /* Border for page buttons */
  border-radius: 5px; /* Rounded corners for buttons */
  padding: 8px 12px; /* Padding inside buttons */
  color: #007bff; /* Text color */
  margin: 0 5px; /* Space between buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
}

.custom-paginator .p-paginator-page:hover {
  background-color: #007bff; /* Background on hover */
  color: white; /* Text color on hover */
}

.custom-paginator .p-paginator-prev,
.custom-paginator .p-paginator-next {
  background-color: #007bff; /* Background color for prev/next buttons */
  color: white; /* Text color for prev/next buttons */
  border: none; /* Remove border */
  padding: 8px 12px; /* Padding inside buttons */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Smooth transition */
}

.custom-paginator .p-paginator-prev:hover,
.custom-paginator .p-paginator-next:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.custom-paginator .p-paginator-current {
  font-weight: bold; /* Highlight the current page */
  color: #333; /* Color for the current page */
}
